<template>
	<div class="item">
		<div class="wrap">
			<h1 class="title" :id="id" :href="`#${id}`">{{ id }}</h1>

			<div class="props">
				<Table :data="propsTable" row-key="rowKey" size="small" stripe>
					<TableColumn prop="name" label="Name" />
					<TableColumn prop="type" label="Type" />
					<TableColumn prop="required" label="Required" />
					<TableColumn prop="map" label="Map" />
					<TableColumn prop="default" label="Default" />
					<TableColumn label="Note" v-slot="{row}">
						<div v-html="row.note" />
					</TableColumn>
				</Table>
			</div>

			<div class="field-wrap">
				<field
					ref="field"
					:alt="{data, loading: false}"
					:name="id"
					:fieldType="fieldType"
					@event="event"
				/>
			</div>

			<div class="info">
				<div class="block props">
					<div class="title">Props</div>
					<pre>
						<code>{{propsDisplay}}</code>
					</pre>
				</div>

				<div class="block data">
					<div class="title">Data</div>
					<pre>
						<code>{{dataDisplay}}</code>
					</pre>
				</div>

				<div class="block event" v-if="eventData">
					<div class="title">Event</div>
					<pre>
						<code>{{eventData}}</code>
					</pre>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import field from "@/components/field";
import {pickBy, get} from "lodash";

export default {
	components: {field, Table, TableColumn},
	props: {
		id: {type: String, required: true},
		props: {type: Object, required: true},
		res: {type: Object, required: true}
	},
	data() {
		return {
			propsDisplay: this.res.props,
			dataDisplay: this.res.data,
			data: this.res.data,
			eventData: null
		};
	},
	computed: {
		fieldType: (t) => ({id: t.id, props: t.res.props}),

		propsTable() {
			let rowKey = 0;

			const mapProps = (props) => {
				return Object.entries(props).map(([key, prop]) => ({
					...prop,
					get type() {
						const names = () =>
							(prop.type || []).map((x) => x.name).join(" | ");
						const name = () => get(prop.type, "name");
						return name() || names();
					},
					get default() {
						return typeof prop.default === "function"
							? JSON.stringify(prop.default())
							: prop.default;
					},
					name: key.charAt(0) === "_" ? key.substr(1) : key,
					map: (key.charAt(0) !== "_").toString(),
					children: mapProps(prop.children || {}),
					required: (!!prop.required).toString(),
					rowKey: rowKey++
				}));
			};

			return mapProps(pickBy(this.props, (x) => x.doc));
		}
	},
	methods: {
		async event({actions, done}) {
			if (actions.update) {
				const {data} = actions.update;
				this.data = {...this.data, ...data};
				this.eventData = data;
			}

			if (done) await done();
		},

		check() {
			if (!this.res) {
				console.warn(this.name);
			}
		}
	},
	created() {
		this.check();
	}
};
</script>

<style lang="scss" scoped>
.item {
	border-bottom: 1px solid $blue2;
	padding: 5em 0;

	&:last-child {
		border-bottom: 0;
	}

	.wrap {
		h1.title {
			border-bottom: 0;
			padding-bottom: 0;
		}

		> .props {
			border: 1px solid $blue2;
			border-radius: 4px;
			overflow: hidden;
			border-bottom: 0;
			margin: 1em 0;

			::v-deep .el-table .cell {
				word-break: normal;
				color: $black1;
			}
		}

		.field-wrap {
			position: relative;
			margin: 2em 0;
			padding: 0.1em 1em;
			border-left: 3px solid $blue2;
		}

		.info {
			margin: 1em 0;
			display: flex;

			.block {
				font-size: 0.8em;
				margin-right: 2em;

				.title {
					margin-bottom: 0.5em;
					font-size: 1.2em;
					font-weight: 500;
				}

				pre {
					display: inline-flex;
					background-color: rgba(black, 0.05);
					margin: 0;
					border-radius: 2px;
					padding: 1em 1.5em;
					padding-right: 2em;
				}

				&.event {
					pre {
						max-width: 25em;
						overflow: auto;
					}
				}
			}
		}
	}
}
</style>

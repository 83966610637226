<template>
	<div class="header">
		<div
			class="image"
			:style="{backgroundImage: `url('${propsData.image}')`}"
			v-if="propsData.image"
		/>

		<div class="info">
			<div class="title">
				<div class="text">{{ propsData.title }}</div>
				<Tag size="small">{{ propsData.tag }}</Tag>
			</div>
		</div>
	</div>
</template>

<script>
import {mapProps} from "@/modules/utils";
import {Tag} from "element-ui";

export default {
	components: {Tag},
	props: {
		header: {type: Object, required: true},
		data: {type: Object, required: true}
	},
	computed: {
		props: (t) => t.header.props,
		propsData: (t) => mapProps({props: t.props, data: t.data})
	}
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	margin-bottom: 2em;

	.image {
		$s: 140px;
		width: $s;
		height: $s;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-right: 2em;
	}

	.info {
		margin-top: 1em;

		.title {
			display: flex;
			align-items: center;

			.text {
				font-size: em(20);
				margin-right: 0.75em;
			}
		}
	}
}
</style>

import {api} from "@/modules/axios";

export default {
	namespaced: true,

	state: {
		data: null
	},

	mutations: {
		data(state, data) {
			state.data = data;
		}
	},

	actions: {
		async data({commit}) {
			try {
				const {data} = await api.get("definitions/config", {customErr: true});
				commit("data", data);
			} catch (err) {
				// error
			}
		}
	},

	getters: {
		data(state) {
			return state.data;
		},
		menu(state, {data}) {
			return data && data.menu;
		},
		tables(state, {data}) {
			return data && data.tables;
		},
		settings(state, {data}) {
			return data && data.settings;
		},
		dashboards(state, {data}) {
			return data && data.dashboards;
		},
		locale(state, {settings}) {
			return settings && settings.locale;
		},
		frontpage(state, {settings}) {
			return settings && settings.frontpage;
		},
		ws(state, {settings}) {
			return settings && settings.websockets;
		},
		title(state, {settings}) {
			return (settings && settings.title) || "Travy";
		}
	}
};

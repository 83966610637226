<template>
	<div class="checkbox">
		<Checkbox v-bind="{value}" @change="update" />
	</div>
</template>

<script>
import {Checkbox} from "element-ui";

export default {
	components: {Checkbox},
	meta: {
		res: {
			props: {
				value: "checkbox"
			},
			data: {
				checkbox: false
			}
		}
	},
	props: {
		value: {type: Boolean, required: false, doc: true},
		_value: {type: String, required: true}
	},
	methods: {
		update(val) {
			this.$emit("event", {
				actions: {
					update: {data: {[this._value]: val}}
				}
			});
		}
	}
};
</script>
